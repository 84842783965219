import React from 'react'
import Bathacessoriespage from './Bathacessoriespage'
const Bathacessories = () => {
  return (
    <div>
       
    <Bathacessoriespage/>
      </div>
  )
}

export default Bathacessories
