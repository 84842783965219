import React from 'react'
import Newarrivalpage from './Newarrivalpage'
const Newarrivals = () => {
  return (
    <div>
         
  <Newarrivalpage/>
    </div>
  )
}

export default Newarrivals
