import React from 'react'
import Foodspage from './Foodspage'
const Foodsproduct = () => {
  return (
    <div>
       
  <Foodspage/>
    </div>
  )
}

export default Foodsproduct