import React from 'react'
import "../Style.css"
import Footer from '../Footer'
const Diytools = () => {
  return (
    <div>
      <div>
  <h2 className="newarrival">Diy / Tools</h2>
  <hr />
  <hr className="mt-5" />
 
  <div>
    <div className="d-flex">
      <div className="ms-4 mt-0px ligh"><h2 className="newar">Shop By</h2>
        <h2 className="newarr">STOCK STATUS </h2>
        <p className="mew">IN STOCK <input type="checkbox" id="subscribe" name="subscribe" defaultValue="yes" className="ms-5" /></p> 
        <p className="mew">OUT OF STOCK  <input type="checkbox" id="subscribe" name="subscribe" defaultValue="yes" className="ms-2" /></p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_fdc1f_096436.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">30X11 PASTEL FOILED STICKERS</h2>
                <h6 className="code"><b>Code:</b>  001587</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_40fd8_038072.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">5G COLOURED CRAFT
                  FEATHERS</h2>
                <h6 className="code"><b>Code:</b> 139974</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_68015_001307.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ACRYLIC SCATTER CRYSTALS...</h2>
                <h6 className="code"><b>Code:</b> 051019</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_01774_017078.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST BRUSHES PK6</h2>
                <h6 className="code"><b>Code:</b> 051018</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>    
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_a1fe5_017073.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST PENCILS PK12</h2>
                <h6 className="code"><b>Code:</b>  104455</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_a1fe5_017073.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST SKETCH PENCILS PK5 <br /></h2>
                <h6 className="code"><b>Code:</b> 104458</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_c48bd_017080.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ASSTD BEADS</h2>
                <h6 className="code"><b>Code:</b> 101841</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_711d0_017079.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">BURNER 9CM</h2>
                <h6 className="code"><b>Code:</b>089298</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_b1535_017067.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CHILTERN ARTS ACRYLIC PAINT
                  TUBE 120ML<br /></h2>
                <h6 className="code"><b>Code:</b> 123342</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_7cfb6_017069.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CHILTERN ARTS WATER
                  COLOUR PAINTS 10PK</h2>
                <h6 className="code"><b>Code:</b>1000535</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_108b0_060043.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BABY ACCESSORIES</h2>
                <h6 className="code"><b>Code:</b>111803</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_cace4_017081.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BAGS CLEAR 6X4CM
                  PK150</h2>
                <h6 className="code"><b>Code:</b>121944</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_357ce_036219.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BUNTING 1M </h2>
                <h6 className="code"><b>Code:</b>121943</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_bad14_039086.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CERAMIC PAINTS 4PC</h2>
                <h6 className="code"><b>Code:</b>  E000069</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_47934_040944.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BAGS CLEAR 6X4CM
                  PK150 </h2>
                <h6 className="code"><b>Code:</b>  137011</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_8ddb6_005663.jpg " className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BALLS 180PC</h2>
                <h6 className="code"><b>Code:</b> E000073</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_b9972_039087.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BUNTING 1M</h2>
                <h6 className="code"><b>Code:</b>E000071</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_63061_039644.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CERAMIC PAINTS 4PC</h2>
                <h6 className="code"><b>Code:</b>137010</h6>
                <button className="butt ">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_4c9b0_039643.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CONTAINER 6PC..</h2>
                <h6 className="code"><b>Code:</b> E000032</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_5f565_098529.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT GLASS TEST TUBES 4PCE</h2>
                <h6 className="code"><b>Code:</b> E000027</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Footer/>
    </div>
  )
}

export default Diytools