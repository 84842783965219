import React from 'react'
import Bathroompage from './Bathroompage'
const Bathroomp = () => {
  return (
    <div>
       
  <Bathroompage/>
    </div>
  )
}

export default Bathroomp
