import React from 'react'

import Bathroompage from './Bathroompage'
const Bathroommain = () => {
  return (
    <div>
       
  <Bathroompage/>
    </div>
  )
}

export default Bathroommain
