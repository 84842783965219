import React from 'react'
import Footer from '../Footer'

const Giftcard = () => {
  return (
    <div>
        <div>
  <h2 className="newarrival">New Arrivals</h2>
  <p className="newartext">Browse Wholesale New Arrivals from the UK’s largest independent Wholesaler. This section is updated weekly with brand new <br /> trusted
    products, including seasonal ranges. <br /><br />Keep up to date with our latest products as you never know what profitable products you may find…</p>
  <hr />
  <hr />
  <div className="showing">
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-12 order-md-2 order-1">
        <nav aria-label=" Page navigation example m-5">
          <ul className="pagination justify-content-end gap-md-2 mb-3">
            <li className="page-item disabled">
              {/* <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a> */}
            </li>
            <li className="page-item active"><a className="page-link " href="./Deparments.html">1</a></li>
            <li className="page-item"><a className="page-link " href="./Index.html">2</a></li>
            <li className="page-item"><a className="page-link" href="./Shopbybrand.html">3</a></li>
            <li className="page-item">
              {/* <a class="page-link " href="#">Next</a> */}
            </li>
          </ul>
        </nav>
      </div>
    </div> 
  </div>
  <div>
    <div className="d-flex">
      <div className="ms-4 mt-0px ligh"><h2 className="newar">Shop By</h2>
        <h2 className="newarr">STOCK STATUS </h2>
        <p className="mew">IN STOCK <input type="checkbox" id="subscribe" name="subscribe" defaultValue="yes" className="ms-5" /></p> 
        <p className="mew">OUT OF STOCK  <input type="checkbox" id="subscribe" name="subscribe" defaultValue="yes" className="ms-2" /></p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_911ac_108746.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">30X11 PASTEL FOILED STICKERS</h2>
                <h6 className="code"><b>Code:</b>  001587</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_8e522_137800.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">5G COLOURED CRAFT
                  FEATHERS</h2>
                <h6 className="code"><b>Code:</b> 139974</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_840d7_064492.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ACRYLIC SCATTER CRYSTALS...</h2>
                <h6 className="code"><b>Code:</b> 051019</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/no-image_400.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST BRUSHES PK6</h2>
                <h6 className="code"><b>Code:</b> 051018</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>    
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_eb64c_018873.JPG" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST PENCILS PK12</h2>
                <h6 className="code"><b>Code:</b>  104455</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_b0c36_064702.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ARTIST SKETCH PENCILS PK5 <br /></h2>
                <h6 className="code"><b>Code:</b> 104458</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_b0c36_064702.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">ASSTD BEADS</h2>
                <h6 className="code"><b>Code:</b> 101841</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_6d944_094639.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">BURNER 9CM</h2>
                <h6 className="code"><b>Code:</b>089298</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_8827a_040630.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CHILTERN ARTS ACRYLIC PAINT
                  TUBE 120ML<br /></h2>
                <h6 className="code"><b>Code:</b> 123342</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_79ed2_100305.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CHILTERN ARTS WATER
                  COLOUR PAINTS 10PK</h2>
                <h6 className="code"><b>Code:</b>1000535</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_99bb1_156731.JPG" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BABY ACCESSORIES</h2>
                <h6 className="code"><b>Code:</b>111803</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/no-image_400.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BAGS CLEAR 6X4CM
                  PK150</h2>
                <h6 className="code"><b>Code:</b>121944</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_bab0c_152685.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BUNTING 1M </h2>
                <h6 className="code"><b>Code:</b>121943</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_44519_0002454.JPG" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CERAMIC PAINTS 4PC</h2>
                <h6 className="code"><b>Code:</b>  E000069</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_af575_006992.JPG" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BAGS CLEAR 6X4CM
                  PK150 </h2>
                <h6 className="code"><b>Code:</b>  137011</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_804fc_132953.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BALLS 180PC</h2>
                <h6 className="code"><b>Code:</b> E000073</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_f2c1b_132943.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT BUNTING 1M</h2>
                <h6 className="code"><b>Code:</b>E000071</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_9effa_015658.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CERAMIC PAINTS 4PC</h2>
                <h6 className="code"><b>Code:</b>137010</h6>
                <button className="butt ">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_520bf_116240.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT CONTAINER 6PC..</h2>
                <h6 className="code"><b>Code:</b> E000032</h6>
                <button className="butt mt-3">Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
            <div className="card card12">
              <img src="https://www.efghousewares.co.uk/Content/14/images/Productimages/rsz_0_400_0413b_143925.jpg" className="card-img-top card-titl" alt />
              <div className="card-body">
                <h2 className="pack">CRAFT GLASS TEST TUBES 4PCE</h2>
                <h6 className="code"><b>Code:</b> E000027</h6>
                <button className="butt">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <Footer/>
    </div>
  )
}

export default Giftcard