import React from 'react'
import Bathmatspages from './Bathmatspages'
const Bathmatsmain = () => {
  return (
    <div>
       
  <Bathmatspages/>
    </div>
  )
}

export default Bathmatsmain

