import React from 'react'
import Disposiblepage from './Disposiblepage'
const Disposiblemain = () => {
  return (
    <div>
       
  <Disposiblepage/>
    </div>
  )
}

export default Disposiblemain;