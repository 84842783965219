import React from 'react'
import Lighterpage from './Lighterpage'
const Lighter = () => {
  return (
    <div>
       
  <Lighterpage/>
    </div>
  )
}

export default Lighter
