import React from 'react'
import Tablewearpage from './Tablewearpage'
const Tablewear = () => {
  return (
    <div>
       
  <Tablewearpage/>
    </div>
  )
}

export default Tablewear;