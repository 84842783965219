import React from 'react'
import Showheadpage from './Showheadpage'
const Showhead = () => {
  return (
    <div>
       
  <Showheadpage/>
    </div>
  )
}

export default Showhead