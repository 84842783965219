import React from 'react'
import Airerspage from './Airerspage'
const Airers = () => {
  return (
    <div>
       
  <Airerspage/>
    </div>
  )
}

export default Airers;