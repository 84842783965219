import React from 'react'
import Showerpage from './Showerpage'
const Showermain = () => {
  return (
    <div>
       
  <Showerpage/>
    </div>
  )
}

export default Showermain