import React from 'react'
import Drinkpage from './Drinkpage'
const Drinkproduct = () => {
  return (
    <div>
       
  <Drinkpage/>
    </div>
  )
}

export default Drinkproduct