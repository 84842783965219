import React from 'react'

import Smokingesspage from './Smokingesspage'
const Smokingessentials = () => {
  return (
    <div>
       
  <Smokingesspage/>
    </div>
  )
}

export default Smokingessentials