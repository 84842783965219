import React from 'react'
import Electricaldepage from './Electricaldepage'
const Electricalde = () => {
  return (
    <div>
       
  <Electricaldepage/>
    </div>
  )
}

export default Electricalde

